import axios from "./api";
//登录日志
export const AsyncLog = params => {
  return axios.post(`/wt/LoginInfo/AsyncLog?userId=`+params.userId+'&type='+params.type);
};
//任务通用分类模块管理
export const GetClassifyList = params => {
  return axios.get(`/wt/TaskCommonClassify/GetClassifyList`, { params: params });
};
export const QueryAllClassifyList = params => {
  return axios.get(`/wt/TaskCommonClassify/QueryAllClassifyList`, { params: params });
}
export const AddTaskCommonClassify = params => {
  return axios.post(`/wt/TaskCommonClassify/AddTaskCommonClassify`, params);
};
export const DeleteTaskCommonClassify = params => {
  return axios.post(`/wt/TaskCommonClassify/SetTaskCommonClassifyEnableStatus?id=` + params.id + `&enabled=` + params.enabled);
};
export const UpdateTaskCommonClassify = params => {
  return axios.put(`/wt/TaskCommonClassify/UpdateTaskCommonClassify?id=` + params.Id + `&displayOrder=` + params.DisplayOrder);
};
//任务类型配置模块管理
export const QueryAllClassifyConfigList = params => {
  return axios.get(`/wt/TaskCommonClassifyConfig/QueryAllClassifyConfigList`, { params: params });
};
export const SetCommonClassifyConfig = params => {
  return axios.post(`/wt/TaskCommonClassifyConfig/SetCommonClassifyConfig`, params);
}
//任务模块管理
export const QueryTaskByUserCode = params => {
  return axios.get(`/wt/Task/QueryPageTasksByUserCode`, { params: params });
}
export const AddTaskAndAssign = params => {
  return axios.post(`/wt/Task/AddTaskAndAssign`, params);
};
export const AddTask = params => {
  return axios.post(`/wt/Task/AddTask`, params);
};
export const CheckTaskWorkItem = params => {
  return axios.post(`/wt/Task/CheckTaskWorkItem?userCodes=`+params.userCodes,params.workitems);
};
export const AddTaskForMeeting = params => {
  return axios.post(`/wt/Task/AddTaskForMeeting`, params);
};
export const AddTask20210524 = params => {
  return axios.post(`/wt/Task/AddTask20210524`, params);
};
export const DeleteTaskById = params => {
  return axios.delete(`/wt/Task/DeleteTaskById`, { params: params });
};
export const UpdateTaskContent = params => {
  return axios.put(`/wt/Task/UpdateTaskContent`, params);
};
export const AssignTask = params => {
  return axios.post(`/wt/Task/AssignTask`, params);
};
export const QueryPageWaitApprovalTasksByUserCode = params => {
  return axios.get(`/wt/Task/QueryPageWaitApprovalTasksByUserCode`, { params: params });
};
export const CancelTask = params => {
  return axios.post(`/wt/Task/CancelTask?taskId=` + params.taskId + `&wantCancelUserCode=` + params.wantCancelUserCode+ `&wantCancelUserName=` +params.wantCancelUserName);
};
export const ProlongPlanComplateTime = params => {
  return axios.post(`/wt/Task/ProlongPlanComplateTime?taskId=` + params.taskId + `&newdate=` + params.newdate);
};
export const CancelTaskForLiudong = params => {
  return axios.post(`/wt/Task/CancelTaskForLiudong?taskId=` + params.taskId + `&wantCancelUserCode=` + params.wantCancelUserCode+ `&wantCancelUserName=` +params.wantCancelUserName);
};
export const QueryChildTasksByParentId = params => {
  return axios.get(`/wt/Task/QueryChildTasksByParentId`, { params: params });
};
export const QueryPageWaitDoTasksByUserCode = params => {
  return axios.get(`/wt/Task/QueryPageWaitDoTasksByUserCode`, { params: params });
};
export const QueryPageReviewTasksByUserCode = params => {
  return axios.get(`/wt/Task/QueryPageReviewTasksByUserCode`, { params: params });
};
export const QueryPageLikeTasksByUserCode = params => {
  return axios.get(`/wt/Task/QueryPageLikeTasksByUserCode`, { params: params });
};
export const QueryPageMyCollectTasksByUserCode = params => {
  return axios.get(`/wt/Task/QueryPageMyCollectTasksByUserCode`, { params: params });
};
export const QueryWaitApprovalChildTasksByParentId = params => {
  return axios.get(`/wt/Task/QueryWaitApprovalChildTasksByParentId`, { params: params });
};
export const QueryPageComplatedTasksByUserCode = params => {
  return axios.get(`/wt/Task/QueryPageComplatedTasksByUserCode`, { params: params });
};
export const QueryPageProcessedTasksByUserCode = params => {
  return axios.get(`/wt/Task/QueryPageProcessedTasksByUserCode`, { params: params });
};
export const QueryPageProcessedTasksByUserCode20210130 = params => {
  return axios.get(`/wt/Task/QueryPageProcessedTasksByUserCode20210130`, { params: params });
};
export const ActivateTask = params =>{
  return axios.post(`/wt/Task/ActivateTask?taskId=` + params.taskId+ `&userCode=` +params.userCode+ `&userName=` +params.userName);
};
export const GetMyUpLeader = params => {
  //return axios.get(`/wt/Task/GetMyUpLeader?userCode=` + params.userCode );
};
export const QueryTasksById = params => {
  return axios.get(`/wt/Task/QueryTasksById`, { params: params });
};
export const GetRemoteConfigValue = params => {
  return axios.get(`/wt/Task/getRemoteConfigValue`, { params: params });
};
export const ReSubmitApproval = params => {
  return axios.post(`/wt/Task/ReSubmitApproval?taskId=`+ params.taskId + `&taskClassify=` +params.taskClassify+ `&taskParentId=` +params.taskParentId);
};
export const QueryTaskExtendDataByTaskId = params => {
  return axios.get(`/wt/Task/QueryTaskExtendDataByTaskId`, { params: params });
};
export const QueryPageWaitReadTasksByUserCode = params => {
  return axios.get(`/wt/Task/QueryPageWaitReadTasksByUserCode`, { params: params });
};
export const QueryPageWaitReadTasksByUserCode201228 = params => {
  return axios.get(`/wt/Task/QueryPageWaitReadTasksByUserCode201228`, { params: params });
};
export const QueryPageTasksByWhere = params => {
  return axios.get(`/wt/Task/QueryPageTasksByWhere`, { params: params });
};
export const QueryPageYearplanTasksByWhere = params => {
  return axios.get(`/wt/Task/QueryPageYearplanTasksByWhere`, { params: params });
};
export const QueryPageYearplanTasksByWhereForJixiaopinggu = params => {
  return axios.get(`/wt/Task/QueryPageYearplanTasksByWhereForJixiaopinggu`, { params: params });
};
export const QueryPageTasksByWhere20210126 = params => {
  return axios.get(`/wt/Task/QueryPageTasksByWhere20210126`, { params: params });
};
export const QueryPageTasksByWhere20220601 = (params,userstr) => {
  return axios.post(`/wt/Task/QueryPageTasksByWhere20220601`, 'userCode='+userstr, { params: params });
};
export const QueryYearPlanAboutTaskByYearplanId = params =>{
  var querySelfVal = params.querySelf == undefined?false:params.querySelf;
  return axios.get(`/wt/Task/QueryYearPlanAboutTaskByYearplanId?yearplantaskid=`+params.yearplantaskid+'&taskclassify='+params.taskclassify+'&month='+params.month+'&month2='+params.month2+'&querySelf='+querySelfVal+'&pageIndex=1&pageSize=500');
}
export const QueryPageTodayOperatorTasksByUsercode = params => {
  return axios.get(`/wt/Task/QueryPageTodayOperatorTasksByUsercode`, { params: params });
};
export const QueryPageTodayOperatorProcessByUsercode = params => {
  return axios.get(`/wt/GradPlatform/QueryPageTodayOperatorProcessByUsercode`, { params: params });
};
export const TaskReadToComplateById = params => {
  return axios.put(`/wt/Task/TaskReadToComplateById?id=`+ params.id);
}
export const SetAllWaitReadTaskReadComplated = params => {
  return axios.post(`/wt/Task/SetAllWaitReadTaskReadComplated?usercode=`+params.usercode);
}
export const SetReadComplatedByWhere = params => {
  return axios.post(`/wt/Task/SetReadComplatedByWhere?taskId=`+params.taskId+'&readusercode='+params.readusercode+'&notaskType='+params.notaskType);
}
export const GetHomeOverviewNumberData = params => {
  return axios.get(`/wt/Task/GetHomeOverviewNumberData`, { params: params });
}
export const GetTaskNumberDataGroupByClassify = params => {
  return axios.get(`/wt/Task/GetTaskNumberDataGroupByClassify`, { params: params });
}
export const GetColumnChartDataGroupByClassifyAndWeek = params => {
  return axios.get(`/wt/Task/GetColumnChartDataGroupByClassifyAndWeek`, { params: params });
}
export const GetTaskProgressChartData = params => {
  return axios.get(`/wt/Task/GetTaskProgressChartData`, { params: params });
}
export const GetPeopleProgressChartData = params => {
  return axios.post(`/wt/Task/GetPeopleProgressChartData?year=`+params.year,params.peoples);
}
export const GetPeopleTaskNumberGroupByClassifyChartData = params =>{
  return axios.post(`/wt/Task/GetPeopleTaskNumberGroupByClassifyChartData?year=`+params.year,params.peoples);
}
export const GetPeopleTaskNumberByTodayLogData = params =>{
  var d = params.d == undefined?0:params.d;
  return axios.post(`/wt/Task/GetPeopleTaskNumberByTodayLogData?date=`+params.date+'&d='+d,params.peoples);
}
export const QueryPeoplePingfenReport = params =>{
  return axios.post(`/wt/TaskPingfen/QueryPeoplePingfenReport?month=`+params.month,params.peoples);
}
export const QueryApprovalPingfenReport = params =>{
  return axios.post(`/wt/Task/QueryApprovalPingfenReport?month=`+params.month,params.peoples);
}
export const GetPeopleProcessNumberByTodayLogData = params =>{
  return axios.post(`/wt/GradPlatform/GetPeopleProcessNumberByTodayLogData?date=`+params.date,params.peoples);
}
export const GetPeopleComplatedTaskNumberGroupByTimeChartData = params => {
  return axios.post(`/wt/Task/GetPeopleComplatedTaskNumberGroupByTimeChartData?year=`+params.year,params.peoples);
}
export const GetEachCompanyUsingTaskNumberGroupByDayChartData = params => {
  return axios.post(`/wt/Task/GetEachCompanyUsingTaskNumberGroupByDayChartData?beforeday=`+params.beforeday,params.companylist);
}
export const GetEachPeoplesUsingTaskNumberGroupByDayChartData = params => {
  return axios.post(`/wt/Task/GetEachPeoplesUsingTaskNumberGroupByDayChartData?month=`+params.month+'&beginDate='+params.beginDate+'&endDate='+params.endDate,params.userlist);
}
export const GetEachPeoplesJixiaoNumberByDayChartData = params => {
  return axios.post(`/wt/Task/GetEachPeoplesJixiaoNumberByDayChartData?beginmonth=`+params.beginmonth+'&endmonth='+params.endmonth,params.userlist);
}
//任务进度模块管理
export const AddTaskProgress = params => {
  return axios.post(`/wt/TaskProgress/AddTaskProgress`, params);
};
export const QueryTaskProgressByTaskId = params => {
  return axios.get(`/wt/TaskProgress/QueryTaskProgressByTaskId`, { params: params });
};
export const UpdateTaskProgress = params => {
  return axios.put(`/wt/TaskProgress/UpdateTaskProgress`, params);
};
export const DeleteTaskProgress = params => {
  return axios.delete(`/wt/TaskProgress/DeleteTaskProgress`, { params: params });
}
//任务指标模板配置
export const AddTemplate = params => {
  return axios.post(`/wt/TaskValueTemplate/AddTemplate`, params);
};
export const UpdateTemplate = params => {
  return axios.post(`/wt/TaskValueTemplate/UpdateTemplate`, params);
};
export const DelTemplate = params => {
  return axios.post(`/wt/TaskValueTemplate/DelTemplate?templateId=`+params.templateId);
};
export const RenameTemplate = params => {
  return axios.post(`/wt/TaskValueTemplate/RenameTemplate?templateId=`+params.templateId+'&newName='+params.newname);
};
export const ChangeTemplatePublicStatus = params => {
  return axios.post(`/wt/TaskValueTemplate/ChangeTemplatePublicStatus?templateId=`+params.templateId+'&ispublic='+params.ispublic);
};
export const QueryPageTemplateByUserCode = params => {
  return axios.get(`/wt/TaskValueTemplate/QueryPageTemplateByUserCode`, { params: params });
};
export const QueryTemplateDetailByTemplateId = params => {
  return axios.get(`/wt/TaskValueTemplateDetail/QueryTemplateDetailByTemplateId`, { params: params });
};
//添加任务进度评价
export const AddTaskProgressReview = params => {
  return axios.post(`/wt/TaskProgressReview/AddTaskProgressReview`, params);
};
export const QueryReviewByProgressId = params => {
  return axios.get(`/wt/TaskProgressReview/QueryReviewByProgressId`, { params: params });
};
export const QueryReviewByProgressId221107 = params => {
  return axios.get(`/wt/TaskProgressReview/QueryReviewByProgressId221107`, { params: params });
};
//添加任务点赞
export const AddTaskProgressLike = params => {
  return axios.post(`/wt/TaskProgressLike/AddTaskProgressLike`, params);
};
export const DeleteTaskProgressLike = params => {
  return axios.delete(`/wt/TaskProgressLike/DeleteTaskProgressLike`, { params: params });
}
//添加任务关注
export const AddTaskCollect = params => {
  return axios.post(`/wt/TaskCollect/AddTaskCollect`, params);
};
export const RemoveTaskCollect = params => {
  return axios.post(`/wt/TaskCollect/RemoveTaskCollect`, params);
};
export const ChangeYearplanTaskParentIdById = params => {
  return axios.post(`/wt/Task/ChangeYearplanTaskParentIdById?parentId=`+params.parentId+'&taskId='+params.taskId );
};
export const SetTaskTemplateId = params => {
  return axios.post(`/wt/Task/SetTaskTemplateId?templateId=`+params.templateId+'&taskId='+params.taskId );
};
//审核任务模块管理
export const ApproveTaskByTaskId = params => {
  return axios.post(`/wt/TaskApproval/ApproveTaskByTaskId`, params);
}
//年度计划模块管理
export const QueryPageYearPlanByUserCode = params => {
  return axios.get(`/wt/YearPlan/QueryPageYearPlanByUserCode`, { params: params });
};
export const QueryPageMyImportedYearPlan = params => {
  return axios.get(`/wt/YearPlan/QueryPageMyImportedYearPlan`, { params: params });
};
export const AddYearPlan = params => {
  return axios.post(`/wt/YearPlan/AddYearPlan`, params);
};
export const QueryYearPlanChildTasksByParentId = params => {
  return axios.get(`/wt/YearPlan/QueryChildTasksByParentId`,{ params: params })
};
export const UpdateYearPlanTaskById = params => {
  return axios.put(`/wt/YearPlan/UpdateYearPlanTaskById`, params);
};
export const ImportYearPlan = params => {
  return axios.post(`/wt/YearPlan/ImportYearPlan`, params);
};
export const ImportYearPlan20211213 = params => {
  return axios.post(`/wt/YearPlan/ImportYearPlan20211213`, params);
};
export const DecomposesYearPlan = params => {
  return axios.post(`/wt/YearPlan/DecomposesYearPlan`, params);
};
export const GetYearPlanListByUserCode = params => {
  return axios.get(`/wt/YearPlan/GetYearPlanListByUserCode`, { params: params });
}
export const DownloadTemplate = params => {
  // return axios.get(`/wt/YearPlan/DownloadTemplate`,{ responseType: 'blob'} );
  return axios.get(`/wt/YearPlan/DownloadTemplate`,{responseType:'blob'})
}

export const QueryConfigInfoByYearPlanId = params => {
  return axios.get(`/wt/YearPlanConfig/QueryConfigInfoByYearPlanId`, { params: params });
}

export const SetTaskAboutYearPlan = params => {
  return axios.post(`/wt/Task/SetTaskAboutYearPlan?taskId=`+ params.taskId + `&yearplanId=` +params.yearplanId);
}

//跨系统、部门协同模块管理
export const QueryPageOrganizeCoByUserCode = params => {
  return axios.get(`/wt/OrganizeCollaboration/QueryPageOrganizeCoByUserCode`, { params: params });
};
export const AddOrganizeCo = params => {
  return axios.post(`/wt/OrganizeCollaboration/AddOrganizeCo`, params);
};
export const QueryOrganizeChildTasksByParentId = params => {
  return axios.get(`/wt/OrganizeCollaboration/QueryChildTasksByParentId`, { params: params });
}
//例会维护模块管理
export const QueryMeetingUphold = params => {
  return axios.get(`/wt/MeetingUphold/QueryMeetingUphold`, { params: params });
};
export const AddRegularMeeting = params => {
  return axios.post(`/wt/MeetingUphold/AddMeeting`, params);
};
export const AddTaskCollectPerson = params => {
  return axios.post(`/wt/TaskCollectPerson/AddTaskCollectPerson`, params);
};
export const EnableTaskCollectPerson = params => {
  return axios.post(`/wt/TaskCollectPerson/EnableTaskCollectPerson`, params);
};
export const QueryMeetingUpholdByCreate = params => {
  return axios.get(`/wt/MeetingUphold/QueryMeetingUpholdByCreate`, { params: params });
}
export const QueryCollectPersonListByCreatedId = params => {
  return axios.get(`/wt/TaskCollectPerson/QueryCollectPersonListByCreatedId`, { params: params });
}
export const DeleteMeetingUpholdById = params => {
  return axios.delete(`/wt/MeetingUphold/DeleteMeetingUpholdById`, { params: params });
};
export const RemoveTaskCollectPerson = params => {
  return axios.post(`/wt/TaskCollectPerson/RemoveTaskCollectPerson`, params);
};
export const UpdateMeetingUphold = params => {
  return axios.put(`/wt/MeetingUphold/UpdateMeetingUphold`, params);
}
export const UpdateMeetingUpholdCCUser = params => {
  return axios.put(`/wt/MeetingUphold/UpdateMeetingUpholdCCUser`, params);
}
export const UpdateMeetingUpholdRangeUser = params => {
  return axios.put(`/wt/MeetingUphold/UpdateMeetingUpholdRangeUser`, params);
}
export const UpdateMeetingUpholdFabuUser = params => {
  return axios.put(`/wt/MeetingUphold/UpdateMeetingUpholdFabuUser`, params);
}
//会议维护
export const AddMeeting = params => {
  return axios.post(`/wt/Meeting/AddMeeting`, params);
};
export const PublishMeetingById = params => {
  return axios.post(`/wt/Meeting/PublishMeetingById?meetingId=`+params.meetingId);
};
export const UpdateMeeting = params => {
  return axios.put(`/wt/Meeting/UpdateMeeting`, params);
}
export const QueryPageMeetingListByCreate = params => {
  return axios.get(`/wt/Meeting/QueryPageMeetingListByCreate`, { params: params });
}
export const QueryPageMeetingListByAboutUsercode = params => {
  return axios.get(`/wt/Meeting/QueryPageMeetingListByAboutUsercode`, { params: params });
}
export const QueryPageTasksByMeetingId = params => {
  return axios.get(`/wt/Task/QueryPageTasksByMeetingId`, { params: params });
}
export const QueryMeetingDptList = params => {
  return axios.get(`/wt/Meeting/QueryMeetingDptList`, { params: params });
}
export const QueryUnEndMeetingList = params => {
  return axios.get(`/wt/Meeting/QueryUnEndMeetingList`, { params: params });
}
export const QueryMeetingHistoryUserlist = params => {
  return axios.get(`/wt/Meeting/QueryMeetingHistoryUserlist`, { params: params });
}
export const QueryMeetingListByDptId = params => {
  return axios.get(`/wt/Meeting/QueryMeetingListByDptId`, { params: params });
}
export const QueryAboutMeMeetingList = params => {
  return axios.get(`/wt/Meeting/QueryAboutMeMeetingList`, { params: params });
}
export const QueryMeetingById = params => {
  return axios.get(`/wt/Meeting/QueryMeetingById`, { params: params });
};
export const DeleteMeetingById = params => {
  return axios.delete(`/wt/Meeting/DeleteMeetingById`, { params: params });
};
//会议任务（汇报）
export const AddMeetingTask = params => {
  return axios.post(`/wt/MeetingTask/AddMeetingTask`, params);
};
export const DelMeetingTask = params => {
  return axios.delete(`/wt/MeetingTask/DelMeetingTask`, { params: params });
};
export const QueryPageMeetingTaskListByUsercode = params => {
  return axios.get(`/wt/MeetingTask/QueryPageMeetingTaskListByUsercode`, { params: params });
};
export const SetTaskToJueyiById = params => {
  return axios.post(`/wt/MeetingTask/SetTaskToJueyiById?Id=`+ params.Id + `&jueyi=` +params.jueyi);
}
export const ChangeDisplayOrder = params => {
  return axios.post(`/wt/MeetingTask/ChangeDisplayOrder?Id1=`+ params.Id1 +'&Id2='+ params.Id2 + `&number1=` +params.number1+ `&number2=` +params.number2);
}
//会议问题
export const AddMeetingQuestion = params => {
  return axios.post(`/wt/MeetingQuestion/AddMeetingQuestion`, params);
};
export const UpdateMeetingQuestion = params => {
  return axios.put(`/wt/MeetingQuestion/UpdateMeetingQuestion`, params);
}
export const QueryPageMeetingQuestionListByUsercode = params => {
  return axios.get(`/wt/MeetingQuestion/QueryPageMeetingQuestionListByUsercode`, { params: params });
}
export const DeleteMeetingQuestionById = params => {
  return axios.delete(`/wt/MeetingQuestion/DeleteMeetingQuestionById`, { params: params });
};
//会议纪要
export const AddMeetingNote = params => {
  return axios.post(`/wt/MeetingNote/AddMeetingNote`, params);
};
export const QueryMeetingNoteByMeetingId = params => {
  return axios.get(`/wt/MeetingNote/QueryMeetingNoteByMeetingId`, { params: params });
}
//会议问题评论
export const AddMeetingQuestionReview = params => {
  return axios.post(`/wt/MeetingQuestionReview/AddMeetingQuestionReview`, params);
};
export const QueryReviewByQuestionId = params => {
  return axios.get(`/wt/MeetingQuestionReview/QueryReviewByQuestionId`, { params: params });
}
//查阅权限配置管理
export const QueryAllSearchQuanxianList = params => {
  return axios.get(`/wt/SearchQuanxian/QueryAllSearchQuanxianList`, { params: params });
}
export const QuerySearchQuanxianListByUsercode = params => {
  return axios.get(`/wt/SearchQuanxian/QuerySearchQuanxianListByUsercode?usercode=`+params.usercode, { params: params });
}
export const FormatDepartment = params => {
  return axios.post(`/wt/SearchQuanxian/FormatDepartment`, params.formatdpt );
}
export const AddSearchQuanxian = params => {
  return axios.post(`/wt/SearchQuanxian/AddSearchQuanxian`, params);
};
export const DeleteSearchQuanxianById = params => {
  return axios.delete(`/wt/SearchQuanxian/DeleteSearchQuanxianById`, { params: params });
};
export const UpdateSearchQuanxian = params => {
  return axios.put(`/wt/SearchQuanxian/UpdateSearchQuanxian`, params);
}
//例会项目管理模块
export const AddRoutineMeeting = params => {
  return axios.post(`/wt/RoutineMeetingProject/AddRoutineMeeting`, params);
};
export const AddRoutineMeetingProject = params => {
  return axios.post(`/wt/RoutineMeetingProject/AddRoutineMeetingProject`, params);
};
export const AddRoutineMeetingItem = params => {
  return axios.post(`/wt/RoutineMeetingProject/AddRoutineMeetingItem`, params);
};
export const QueryPageRoutineMeetingTasksByUserCode = params => {
  return axios.get(`/wt/RoutineMeetingProject/QueryPageRoutineMeetingTasksByUserCode`, { params: params });
}
export const QueryPageRoutineMeetingTasksByUserCode20210121 = params => {
  return axios.get(`/wt/RoutineMeetingProject/QueryPageRoutineMeetingTasksByUserCode20210121`, { params: params });
}
//专项会议项目管理模块
export const QueryPageUniquelyProjectMeetingTasksByUserCode = params => {
  return axios.get(`/wt/UniquelyProjectMeeting/QueryPageUniquelyProjectMeetingTasksByUserCode`, { params: params });
};
export const QueryPageUniquelyProjectMeetingTasksByUserCode20210122 = params => {
  return axios.get(`/wt/UniquelyProjectMeeting/QueryPageUniquelyProjectMeetingTasksByUserCode20210122`, { params: params });
};
export const AddUniquelyProjectMeeting = params => {
  return axios.post(`/wt/UniquelyProjectMeeting/AddUniquelyProjectMeeting`, params);
}
//日历模块管理
export const QueryScheduleByUser = params => {
  return axios.get(`/wt/Schedule/QueryScheduleByUser`, { params: params });
};
export const AddSchedule = params => {
  return axios.post(`/wt/Schedule/AddSchedule`, params);
}
//用户组管理
export const QueryGroupPersonListByCreatedUser = params => {
  return axios.get(`/wt/GroupPersonItem/QueryGroupPersonListByCreatedUser`, { params: params });
};
export const AddGroupPersonItem = params => {
  return axios.post(`/wt/GroupPersonItem/AddGroupPersonItem`, params);
}
export const DeleteGroupPersonById = params => {
  return axios.post(`/wt/GroupPersonItem/DeleteGroupPersonById?id=`+params.id );
};
//使用率统计排除人员
export const QueryUseExcludePersonList = params => {
  return axios.get(`/wt/UseExclude/QueryUseExcludePersonList`, { params: params });
};
export const AddUseExclude = params => {
  return axios.post(`/wt/UseExclude/AddUseExclude`, params);
}
export const DeleteExcludePersonById = params => {
  return axios.post(`/wt/UseExclude/DeleteExcludePersonById?id=`+params.id );
};
//消息模块管理
export const QueryMessageListByUserCode = params => {
  return axios.get(`/wt/Message/QueryMessageListByUserCode`, { params: params });
};
export const QueryNewMessageCountByUserCode = params =>{
  return axios.get(`/wt/Message/QueryNewMessageCountByUserCode`, { params: params });
};
export const UpdateMessageStatusById = params => {
  return axios.put(`/wt/Message/UpdateMessageStatusById?messageId=`+ params.messageId + `&userCode=` +params.userCode);
}

//通知模块管理
export const GetNoticeList = params =>{
  return axios.get(`/wt/Notice/GetNoticePageByTitle`, { params: params });
}
export const Add = params => {
  return axios.post(`/wt/Notice/AddNotice`, params);
}
export const UpdateNotice = params => {
  return axios.put(`/wt/Notice/UpdateNotice`, params);
}
export const GetDetail = params => {
  return axios.get(`/wt/Notice/GetNoticeDetailById`, { params: params });
}
export const Delete = params => {
  return axios.delete(`/wt/Notice/DeleteNoticeById`, { params: params });
}


export const GetUserTopDepartmentList = params => {
  // return axios.get(`/uc/api/Department/GetDepartmentNodeRootAllByUserId`, { params: params });
  return axios.get(`/org/dept/getUserHighNodeDept`, { params: params });
}
export const GetChildrenList = params => {
  // return axios.get(`/uc/api/Department/GetDepartmentNodeAllByParentId`, { params: params });
  return axios.get(`/org/dept/orgDeptList`, { params: params });
};
export const GetDepartmentPostUserList = params => {
  // return axios.get(`/uc/api/Department/GetDepartmentUserViewPageById`, { params: params });
  return axios.get(`/org/user/getUserList`, { params: params });
}
//职务模块管理
export const GetUserPostList = params => {
  // return axios.get(`/uc/api/Post/GetPostUserViewAllByUserId`, { params: params });
  return axios.get(`/org/user/getUserDetails`, { params: params });
}
export const GetPostUserPrincipalList = params => {
  return axios.get(`/uc/api/Post/GetPostUserViewPageByUserCodeOrName`, { params: params });
}
export const GetDepartmentList = params => {
  return axios.get(`/uc/api/Department/GetDepartmentNodeAllByName`, { params: params });
}
export const GetPostDepartmentOrganizationViewAllByUserId = params => {
  // return axios.get(`/uc/api/Post/GetPostDepartmentOrganizationViewAllByUserId`, { params: params });
  return axios.get(`/org/user/getUserDetailList`, { params: params });
}
export const GetPostNodeUserViewPageByUserId = params => {
  // return axios.get(`/uc/api/Post/GetPostNodeUserViewPageByUserId`, { params: params });
  return axios.get(`/org/user/getJobNextUserList`, { params: params });
}
export function getOrganizeList (params) {
  return axios.get(`/uc/api/Department/GetOrganizationAll`);
};
export function GetOrganizationAllAndAmount (params) {
  // return axios.get(`/uc/api/Department/GetOrganizationAllAndAmount?postWorkTypeName=后勤`);
  return axios.get(`/org/dept/getOrgDeptUserCount?deptTypeName=公司&workTypeName=后勤`);
};
export const AddTaskPingfen = params => {
  return axios.post(`/wt/TaskPingfen/AddTaskPingfen`, params);
};
export const UpdateTaskPingfen = params => {
  return axios.post(`/wt/TaskPingfen/UpdateTaskPingfen`, params);
};
export const DeleteTaskPingfen = params => {
  return axios.post(`/wt/TaskPingfen/DeleteTaskPingfen?id=`+params.id, params);
};
export const QueryTaskPingfenByTaskId = params => {
  return axios.get(`/wt/TaskPingfen/QueryTaskPingfenByTaskId`, { params: params });
};
//刷新token
export const RefreshToken = params => {
  return axios.post(`https://api.gradgroup.cn/oauth/token`, params, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
};